import React from 'react';
import CityGuide from '../../../components/guides/CityComponent';
import Footer from '../../../components/FooterComponent';

import { Krakow } from '../../../guides/countries/poland/krakow';

function KrakowPage(props) {
    return (
        <div>
            <CityGuide city={ Krakow } pathname={ props.location.pathname } urlBack="/travel-guides/europe" />
            <Footer />
        </div>
    );
}

export default KrakowPage;
