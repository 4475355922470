import React, { Component } from 'react';
import './Guides.css';

import { Nav, Navbar, NavItem, NavLink, NavbarToggler, NavbarBrand, Collapse } from 'reactstrap';
import { Link } from 'gatsby';
import { Figure } from 'react-bootstrap';
import AdjustableImage from '../elements/AdjustableImage';
import TopIcon from '../elements/TopIcon';

import RenderEssentials from '../elements/render/RenderEssentials';
import RenderAccommodations from '../elements/render/RenderAccommodations';
import RenderHighlights from '../elements/render/RenderHighlights';
import RenderRestaurants from '../elements/render/RenderRestaurants';

import PlaceMetadata from '../../metadata/PlaceMetadata';

class CityGuide extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isNavOpen: false
        }

        this.toggleNav = this.toggleNav.bind(this);
        this.closeNav = this.closeNav.bind(this);

        this.topRef = React.createRef();
        this.essentialsRef = React.createRef();
        this.itineraryRef = React.createRef();
        this.highlightsRef = React.createRef();
        this.foodRef = React.createRef();
        this.stayRef = React.createRef();
    }

    toggleNav() {
        this.setState({ isNavOpen: !this.state.isNavOpen });
    }

    closeNav() {
        this.setState({ isNavOpen: false });
    }

    render() {

        const RenderItinerary = ({ itinerary }) => itinerary.map((day, key) => {
            return (
                <div key={ key }>
                    <h4>Day { key + 1 }:</h4>
                    { day.description }
                    <img style={{ marginTop: "20px", marginBottom: '10px' }} width="100%" src={ day.image } alt={ this.props.city.name } fluid="true" />
                    <Figure.Caption className="text-center">{ day.caption }</Figure.Caption>
                </div>
            )
        });

        return (
            <React.Fragment>
                <PlaceMetadata title={ this.props.city.name } description={ this.props.city.description } image={ this.props.city.image }
                    pathname={ this.props.pathname } />
                <TopIcon />
                <div id="page-top" ref={ this.topRef } className='header-topic'>
                    <AdjustableImage image={ this.props.city.image } thumb={ this.props.city.thumb } alt={ this.props.city.name } />
                    <div className="header-text">
                        <p>{ this.props.city.country }</p>
                        <h1 className="header-title">{ this.props.city.name }</h1>
                        <p className="header-caption">{ this.props.city.caption }</p>
                    </div>
                </div>

                <Navbar light sticky="top" className="bg-light border" expand="md">
                    <div className="container">
                        <NavbarBrand className="d-block d-md-none" href="" onClick={() => {
                                this.topRef.current.scrollIntoView();
                                this.closeNav(); }}>
                            { this.props.city.name }
                        </NavbarBrand>
                        <NavbarToggler onClick={ this.toggleNav } />
                        <Collapse isOpen={ this.state.isNavOpen } navbar >
                            <Nav navbar>
                                <NavItem>
                                    <NavLink href="#" onClick={() => {
                                            this.essentialsRef.current.scrollIntoView();
                                            this.closeNav(); }} >Essentials</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#" onClick={() => {
                                            this.itineraryRef.current.scrollIntoView();
                                            this.closeNav(); }}>Itinerary</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#" onClick={() => {
                                            this.highlightsRef.current.scrollIntoView();
                                            this.closeNav(); }}>Highlights</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#" onClick={() => {
                                            this.foodRef.current.scrollIntoView();
                                            this.closeNav(); }}>Food & Drinks</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#" onClick={() => {
                                            this.stayRef.current.scrollIntoView();
                                            this.closeNav(); }}>Where To Stay</NavLink>
                                </NavItem>
                            </Nav>
                            <Nav navbar className="ms-auto">
                                <NavItem>
                                    <NavLink tag={ Link } to={ this.props.urlBack }>{ this.props.city.country }</NavLink>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </div>
                </Navbar>

                <div className="container">
                    <div className="city-header">
                        <h1>{ this.props.city.name } Travel Guide</h1>
                        <div className="col-12 col-sm-10 m-auto">
                            { this.props.city.description }
                        </div>
                    </div>

                    <div className="city-text">
                        <div className="city-topic">
                            <div className="col-12 col-lg-11 ms-auto me-auto" >
                                <h1 id="essentials" ref={ this.essentialsRef }>Essentials</h1>
                                <RenderEssentials title={`How many days in ${this.props.city.name}?`} text={this.props.city.nrDays} />
                                <RenderEssentials title={`When To visit ${this.props.city.name}?`} text={this.props.city.whenVisit} />
                                <RenderEssentials title={`How to get around ${this.props.city.name}?`} text={this.props.city.mobility} />
                                <RenderEssentials title={`Crime and safety in ${this.props.city.name}`} text={this.props.city.safety} />
                            </div>
                        </div>

                        <div className="city-topic">
                            <div className="col-12 col-lg-11 ms-auto me-auto">
                                <h1 id="itinerary" ref={ this.itineraryRef }>Itinerary</h1>
                                <div style={{ marginBottom: '40px' }}>
                                    { this.props.city.itinerary.description }
                                </div>
                                <RenderItinerary itinerary={ this.props.city.itinerary.days } />
                            </div>
                        </div>

                        <div className="city-topic">
                            <div className="col-12 col-lg-11 ms-auto me-auto">
                                <h1 id="highlights" ref={ this.highlightsRef }>Highlights</h1>
                            </div>
                            <RenderHighlights highlights={ this.props.city.highlights } />
                        </div>

                        <div className="city-topic">
                            <div className="col-12 col-lg-11 ms-auto me-auto">
                                <h1 id="food" ref={ this.foodRef }>Food & Drinks</h1>
                                { this.props.city.food.description }
                            </div>
                            <div className="row">
                                <RenderRestaurants restaurants={ this.props.city.food.restaurants } />
                            </div>
                        </div>

                        <div className="city-topic">
                            <div className="col-12 col-lg-11 ms-auto me-auto">
                                <h1 id="whereToStay" ref={ this.stayRef }>Where to Stay</h1>
                                { this.props.city.stay.description }
                            </div>
                            <h4 style={{ marginTop: "40px" }}>Budget Accommodations</h4>
                            <RenderAccommodations accomodations={ this.props.city.stay.budget} />
                            <h4 style={{ marginTop: "40px" }}>Mid-Range Accommodations</h4>
                            <RenderAccommodations accomodations={ this.props.city.stay.mid} />
                            <h4 style={{ marginTop: "40px" }}>High-End Accommodations</h4>
                            <RenderAccommodations accomodations={ this.props.city.stay.high} />
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );

    }
}

export default CityGuide;
