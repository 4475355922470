import React from 'react';
import { Card, CardTitle, CardBody, CardText, CardImg, Media } from 'reactstrap';

import WindowWidthListener from '../WindowWidthListener';

function RenderAccomodations(props) {
    const { width } = WindowWidthListener();

    return props.accomodations.map((accomodation, key) => {
        if (width > 720) {
            return (
                <div className="card-stay" key={ key }>
                    <Media style={{ display: 'flex' }}>
                        <Media center>
                            <Media object width="400px" height="250px" className="header-topic-img" src={ accomodation.image } alt={ accomodation.name } />
                        </Media>
                        <Media body className="ms-3 me-3 mt-3">
                            <h5>{ accomodation.name }</h5>
                            <p>{ accomodation.description }</p>
                        </Media>
                    </Media>
                </div>
            );
        } else {
            return (
                <div key={ key }>
                    <Card className="card-phone-stay col-12">
                        <CardImg top width="100%" src={ accomodation.image } alt={ accomodation.name } />
                        <CardBody>
                            <CardTitle><b>{ accomodation.name }</b></CardTitle>
                            <CardText>{ accomodation.description }</CardText>
                        </CardBody>
                    </Card>
                </div> );
        }
    });
}

export default RenderAccomodations;
