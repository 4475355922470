import React from 'react';

const WindowWidthListener = () => {
    const [width, setWidth] = React.useState(0);

    React.useEffect(() => {
        setWidth(window.innerWidth);
        const resizeListener = () => setWidth(window.innerWidth);
        window.addEventListener("resize", resizeListener);

        return () => window.removeEventListener("resize", resizeListener);
    }, []);

    return { width };
}

export default WindowWidthListener;
