import React from 'react';
import { Media, Card, CardImg, CardBody, CardTitle, CardText } from 'reactstrap';

import WindowWidthListener from '../WindowWidthListener';

function RenderHighlights (props) {
    const { width } = WindowWidthListener();

    return props.highlights.map((highlight, day) => {
        if (width > 720) {
            const MediaImage = (
                <React.Fragment key="left" >
                    <Media key="left">
                        <Media object height="250px" width="250px" className="header-topic-img" src={ highlight.image } alt={ highlight.name } />
                    </Media>
                </React.Fragment>
            );

            const MediaText = (
                <React.Fragment key="right" >
                    <Media body className="ms-3 me-3 mt-3" style={{ width: '100%' }} key="right">
                        <h5>{ highlight.name }</h5>
                        <p>{ highlight.description } </p>
                    </Media>
                </React.Fragment>
            );

            let HighlighMedia;
            if (day % 2 === 0) {
                HighlighMedia = [MediaImage, MediaText];
            } else {
                HighlighMedia = [MediaText, MediaImage];
            }
            return (
                <div key={ day }>
                    <Media className="card-highlights">
                        { HighlighMedia }
                    </Media>
                </div>
            );
        } else {
            return (
                <div key={ day }>
                    <Card className="card-phone-stay col-12">
                        <CardImg top width="100%" src={ highlight.image } alt={ highlight.name } />
                        <CardBody>
                            <CardTitle><b>{ highlight.name }</b></CardTitle>
                            <CardText>{ highlight.description }</CardText>
                        </CardBody>
                    </Card>
                </div> );
        }
    });
}

export default RenderHighlights;
