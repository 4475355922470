import React from 'react';

export const Krakow = {
    "name": "Krakow",
    "link": "krakow",
    "country": "Poland",
    "continent": "Europe",
    "thumb": "https://live.staticflickr.com/65535/50848222703_0f1ddbf811_t.jpg",
    "image": "https://live.staticflickr.com/65535/50848222703_0e9fa014d0_o.jpg",
    "caption": "The city marked by the war",

    "description": "Krakow, Poland, is one of those cities that surprises all visitors, it really happens! A charming, colorful, historic, cheerful city that breathes culture, Krakow is full of adjectives. Its fame is not just a tourist impression, since UNESCO recognized the Historic Center as the First World Heritage Site in 1978, along with eleven other places. There are several interesting trips to take here, all with a lot of history behind them. However, some of them are unfortunately sad, but that is part of the experience as we know. But there is always a positive side to all of this, and a great reason to get to know Krakow's attractions is the fact it is one of the cheapest places in Europe. Without a doubt, quite a trip!",

    "nrDays":<p>
        Krakow city center is not much large and you can visit all the major spots on foot. However, there are a few iconic
        spots that you cannot miss, and when you attach a trip to the famous Aushwitz concentration camps, it makes at least <b>3 days</b> necessary
        to visit this place.
        <br/><br/>
        If you're looking for more outside the city centre trips, like to Zokapane, you will need throw in some extra days for it.
    </p>,

    "whenVisit": <p>
        The best time to visit Poland's postal city is <b>between March and May</b> or <b>between September and November</b>. It's when you can enjoy a
        pleasant weather and avoid the flood of tourists that occurs during the summer months. However, if you feel capable to
        survive at low temperatures, the <b>Christmas season</b> is also a magical time to visit this city full of traditions and thematic markets.
            </p>,

    "mobility": <p>
        Public transportation in Krakow is quite efficient and not expensive. You can always rent a car to move between the various points of interest,
        but we highly recommend <b>using public transports</b>. To make use of the different types of transport you have several ticket options:
        <br/><br/>
        <ul>
            <li>You can buy a single ticket (approximately 1.2$)</li>
            <li>You can buy tickets for a specific time, from 20 minutes to 7 days. For our itinerary, we recommend that you buy the <b>3 or 7 days ticket</b>. The price for 7 days is around 14$.</li>
        </ul>
        Don’t forget that you need to always <b>validate</b> your ticket!
        <br/><br/>
        Alternatively, you can also use some mobile apps instead of buying a ticket locally for the bus or at a tram stop. These apps are <a href=" https://www.skycash.com/ " target="_blank" rel="noopener noreferrer">SkyCash</a> and <a href=" http://www.mobilet.pl/ " target="_blank" rel="noopener noreferrer"> MoBILET </a>. The apps are not
        exclusively for transportation purposes, and they can be useful for your trip.
        To search for the bus or tram that you should get, <a href=" https://jakdojade.pl/krakow/trasa/ " target="_blank" rel="noopener noreferrer"> JakDojade </a> is the perfect app for that. It is very popular in big Polish cities and is very precise.
    </p>,

    "safety": <p>
        Krakow is a <b>very safe city</b> to travel. In the last few years, the number of visitors has increased and therefore the common crime of pickpocketing has increased as well.
        However, it is still an extremely safe city, even at night. But of course, always exert some regular precautions, and be cautious if you are approached by nice girls trying to invite you to
        enter some special club, there is not problem with this, but those clubs generally have an entrance fee.
        </p>,

    "itinerary": {
        "description": "Krakow is full of activities, with all the big attractions relatively close to each other. We will suggest here a 3-day itinerary to cover the major spots and also a visit to Aushwitz and the famous Salt Mines.",
        "days": [
            {
                "description": <p>
                   To start the visit of this wonderful city in the best way, nothing better than taking a free walking tour around the city, you get to know more about
                   the history and culture of Krakow. At the same time, you will be able to go through the main spots of the city. Usually, these free walking tours
                   start in <b>Kazimierz District</b> or <b>Main Market Square</b>. Generally, the tours include <b>Grodzka Street</b>, the <b>Cloth Hall</b>, <b>St. Mary’s Basilica</b>, <b>Town Hall Tower</b>, <b>Oldtown</b>, <b>Jewish Quarter</b>, and
                   of course, one of the main attractions of the city, the <b>Wawel Castle</b> and its grounds.
                   <br/><br/>
                   After, take a full lunch to recover your energy. You can try the famous <b>dumplings</b> (also known as pierogi) or an amazing burger. Once done, you can move forward to the <b>Oskar
                   Schindler Factory</b> (don’t forget to make a reservation for this before, it’s usually packed with a lot of people).
                   <br/><br/>
                   Take the rest of the afternoon to stroll through the
                   narrow streets of the old town, and at night, try the famous city bar, <b>Bania luka</b>.
                    </p>,
                "image": "https://live.staticflickr.com/65535/50848947321_a163988195_o.jpg",
                "caption": "Wawel Castle grounds in a sunny day."
            },
            {
                "description": <p>
                    For the second day we saved the landmark visit to the <b>Auschwitz-Birkenau</b> concentration camp. Although it is possible to go to the fields
                    autonomously, for the prices available in the market we suggest booking a tour from your hotel/hostel (it is still about 60km to the place
                    from the city centre). To optimize this day, it would be ideal to add to this pack the <b>Salt Mines of Wieliczka</b>, which is also found in the
                    vicinity of the city's metropolitan area.
                    <br/><br/>
                    At night, to calm yourself a little from this day full of emotions, we recommend you to do a walking tour to discover the <b>famous legends</b> of the city (do not forget to check in advance which days these tours are made).
                </p>,
                "image": "https://live.staticflickr.com/65535/50849037437_8a33ec05c3_o.jpg",
                "caption": "Auschwitz Concentration Camp."
            },
            {
                "description": <p>
                    During the morning of the third day, you can explore the picturesque streets of the <b>Jewish Quarter</b> and <b>Kazimierz</b> more, with a passage
                    through <b>Ghetto Heroes Square</b>. At lunch, go for a typical sandwich somewhere around the Jewish Quarter. Next, move forward to the Main Market Square and visit
                    the <b>Rynek Underground Museum</b>.
                    <br/><br/>
                    At the end of the afternoon enjoy the grounds of the <b>Wawel Castle</b> and appreciate the sunset with a view to the <b>Vistula River</b>.
                </p>,
                "image": "https://live.staticflickr.com/65535/50849037427_898510787f_o.jpg",
                "caption": "View of the grounds of Wawel Castle."
            }
        ]
    },

    "highlights": [
        {
            "name": "Main Market Square",
            "description": <p>
                This is the largest medieval square in the whole Europe. It was built in the 13th century after the city was destroyed by Tatars.
                In this place you will have the opportunity to find lots of mini markets full of local jewelry and traditional products that you can
                take as a souvenir. During Christmas, the view from this square is wonderful and full of magic.
            </p>,
            "image": "https://live.staticflickr.com/65535/50848223023_1df2620cb6_o.jpg"
        },
        {
            "name": "St. Mary’s Basilica",
            "description": <p>
                Certainly one of the most famous monuments in the city. Entrance to the church is not free, but we recommend paying the price to enter
                and see the amazing bright blue ceiling and the fantastic Gothic altarpiece. You will only be able to climb the tower if you make
                a <b>reservation in advance</b>. Only 10 people every 30 minutes can climb. Visit the <a href="http://mariacki.com/en/" target="_blank" rel="noopener noreferrer">official website</a> to search for available hours.
                The best time to visit it is during the morning. Opening is at 9am, and its when there are less people visiting.
            </p>,
            "image": "https://live.staticflickr.com/65535/50848223008_56235d2ca0_o.jpg"
        },
        {
            "name": "Wawel Castle",
            "description": <p>Located at the top of the hill next to Old Town. There are a lot of activities in the area and you will certainly spend some of your
                time here. Again, visit during the morning when there are less tourists on the interior of the Castle. Near the castle you can find the
                wonderful gardens and the <b>Wawel Cathedral</b>. This is considered the Poland’s Westminster Abbey. The ticket price to enter in the Cathedral is about 3$.
            </p>,
            "image": "https://live.staticflickr.com/65535/50849037372_762d16a20f_o.jpg"
        },
        {
            "name": "Old Town",
            "description": <p>From the Wawel castle, amble up Kanonicza Street, the oldest street in Krakow until you reach the Old Town. Explore the area
                and if you want you have here the chance to visit a small museum called <b>“Krakow in 15 Minutes”</b>, where you will be able to watch a
                multi-media presentation about the history of the city.
            </p>,
            "image": "https://live.staticflickr.com/65535/50849037367_4de38154f0_o.jpg"
        },
        {
            "name": "Oskar Schindler’s Factory",
            "description": <p>
                Probably the best museum in the city. Here, you will know more about the history of Oskar Schindler and his life.
                He is credited with saving more than 1000 Jews during Nazi’s occupation. The entry fee is about 9$ and we highly
                recommend visiting this with a guide, he will give you a lot explanations of how everything occurred.
                Visit the <a href=" https://www.mhk.pl/branches/oskar-schindlers-factory" target="_blank" rel="noopener noreferrer">official website</a> to purchase the tickets in advance.
            </p>,
            "image": "https://live.staticflickr.com/65535/50848947191_97ed3f943d_o.jpg"
        },
        {
            "name": "Auschwitz-Birkenau",
            "description": <p>
               The Auschwitz I and Birkenau II are two of the most famous concentration camps of the Nazi’s occupation. They are certainly two places
               that will mark you and leave you thinking about all the suffering passed on those communities. To visit this place we suggest you
               do it with a guide, as his explanations will give you a different idea about how it all happened.
            </p>,
            "image": "https://live.staticflickr.com/65535/50849037317_f3ebfa74c2_o.jpg"
        },
        {
            "name": "Wieliczka Salt Mines",
            "description": <p>
                As the name implies, these salt mines are located in the city of Wieliczka on the outskirts of Krakow, and here they have been
                producing sodium chloride since the Neolithic era. Surely, an amazing natural spot to mark on your itinerary.
            </p>,
            "image": "https://live.staticflickr.com/65535/50849037312_30cf5b2e5e_o.jpg"
        }
    ],

    "food": {
        "description": <p>
            Certainly, one important thing to do in Krakow is to taste the local cuisine! You can opt to taste the traditional food on the kiosks in Jewish Quarter or ask tips from the locals on the best places to sample food.
            <br/><br/>
            If you are searching for the best dishes, we give you an idea of where to go.
            </p>,

        "restaurants": [
            {
                "name": "La Grande Mamma",
                "description": "An italian and sophisticated place. It's not the cheapest in town, but it has high quality of its dishes.",
                "price": 3,
                "image": "https://live.staticflickr.com/65535/50848222918_88fd8f1ed9_o.jpg"
            },
            {
                "name": "Restauracja Starka",
                "description": "With traditional food to taste, its nicely and goo atmosphere will let you satisfied with the meal.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50848947136_d3ca38e909_o.jpg"
            },
            {
                "name": "Czarna Kaczka The Black Duck",
                "description": "Another great spot to taste traditional food, and one of our favorite spots. Nice staff.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50848947126_32e820ce57_o.jpg"
            },
            {
                "name": "MoaBurguer",
                "description": "Great spot to taste a New Zealand burger. With a lot of options, and it's fast, delicious and cheap.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50849037242_8022bdcf9f_o.jpg"
            },
            {
                "name": "Cyrano de Bergerac",
                "description": "Tapestries, antiques and old implements fill the room, and there’s a romantic atmosphere here. A little expensive for the city, but worth the experience.",
                "price": 4,
                "image": "https://live.staticflickr.com/65535/50849037212_4feece5c27_o.jpg"
            }
        ]
    },

    "stay": {
        "description": <p>
               Krakow has a lot of accommodation options, from hostels to five star hotels. You will easily find an accommodation that suits your expectations.
            <br/><br/>
            Below we give you some suggestions of places to stay for every budget.
        </p>,

        "budget": [
            {
                "name": "Draggo House",
                "description": "This Hostel is located at the old town, which is an excellent location. Very clean and at a good price it is a nice option to go with friends.",
                "image": "https://live.staticflickr.com/65535/50849037207_2e9fb158a6_o.jpg",
                "link": "/"
            },
            {
                "name": "Hostel u Kmity",
                "description": "Another amazing spot basically in the city centre. Fantastic accommodation and nice staff.",
                "image": "https://live.staticflickr.com/65535/50849037187_1e5d8e1de5_o.jpg",
                "link": "/"
            }
        ],
        "mid": [
            {
                "name": "PURO Kraków Kazimierz",
                "description": "Comfortable and large rooms near to Kazimierz with amazing breakfast. ",
                "image": "https://live.staticflickr.com/65535/50848222788_805e79f59b_o.jpg",
                "link": "/"
            }
        ],
        "high": [
            {
                "name": "Radisson Blu Hotel Krakow",
                "description": "One of the most luxurious spots in the city. Placed at the Old Town, with giant rooms and good breakfast, it is a great option if you don't have problems in spend a little more money.",
                "image": "https://live.staticflickr.com/65535/50848222773_69607e518e_o.jpg",
                "link": "/"
            },
            {
                "name": "Little Britain ApartHotel SAUNA&DAILY SPA HEN&STAG DO",
                "description": "Similar comfort to the previous one, but with an ApartHotel style, will let you very satisfied. Fantastic staff.",
                "image": "https://live.staticflickr.com/65535/50848947026_4c35be09c0_o.jpg",
                "link": "/"
            }
        ]
    }
}
